import { copyImageToClipboard } from 'copy-image-clipboard'
import domtoimage from 'dom-to-image-more'
import { useNuxtApp } from 'nuxt/app'

export const common = {
    namespaced: true,
    state: () => ({
        notification: {
            text: '',
            isActive: false
        },
        loading: true,
        sockedConnectionStatus: {
            status: 'WebSocket connecting',
            avaliable: false
        },
        interfaceBlocker: {
            isActive: false,
            progress: 0,
            title: null,
            showQueue: false
        },
        processIsUnderway: {
            status: 'hidden',
            title: null
        },
        requestIsPending: false,
        error: {
            statusCode: '',
            message: '',
            showError: false
        },
        confirm: {
            headerText: '',
            iconPath: '',
            text: '',
            showModal: false,
            buttons: [],
            onClose: null
        },
        options: {
            case: {
                name: '',
                id: undefined
            },
            layer_case: {
                name: '',
                id: undefined,
                type: null
            },
            object: {
                name: '',
                id: undefined
            },
            layer: {
                name: '',
                id: undefined
            },
            project: {
                name: '',
                id: undefined
            },
            engineering_case: {
                name: '',
                id: undefined
            },
            economics_case: {
                name: '',
                id: undefined
            }
        },
        breadcrumbs: [],
        colorPack: [
            '#14223D',
            '#92A2C0',
            '#ee6056',
            '#ff9a85',
            '#E2B71F',
            '#ffda7d',
            '#abf684',
            '#31EB5A',
            '#60d494',
            '#43CDEC',
            '#5d9bd5',
            '#3146FC',
            '#9A5CFF',
            '#FC5CFF',
            '#6ba832',
            '#943518',
            '#aeba09',
            '#0ac3c9',
            '#3d0ac9',
            '#a715eb',
            '#5959b3'
        ],
        notifications: {
            isActive: false,
            items: [
                {
                    text: 'Вы создали',
                    date: '12/12/2022 10:30',
                    entity: {
                        type: 'engineering_case',
                        name: 'Название кейса',
                        id: 6
                    }
                },
                {
                    text: 'Вы удалили',
                    date: '12/12/2022 10:30',
                    entity: {
                        type: 'engineering_case',
                        name: 'Название кейса 2',
                        id: 7
                    }
                },
                {
                    text: 'Вы создали',
                    date: '12/12/2022 10:30',
                    entity: {
                        type: 'layer',
                        name: 'Название пласта 3',
                        id: 8
                    }
                }
            ]
        }
    }),
    mutations: {
        setNotification(state, payload) {
            if (payload.isActive == false) {
                state.notification.isActive = payload.isActive
                state.notification.text = ''
            } else {
                state.notification.isActive = payload.isActive
                state.notification.text = payload.text
            }
        },
        setLoadingStatus(state, payload) {
            state.loading = payload
        },
        blockInterface(state, payload) {
            payload.progress !== undefined ? state.interfaceBlocker.progress = payload.progress : false;
            payload.isActive !== undefined ? state.interfaceBlocker.isActive = payload.isActive : false;
            payload.title !== undefined ? state.interfaceBlocker.title = payload.title : false;
            payload.showQueue !== undefined ? state.interfaceBlocker.showQueue = payload.showQueue : false;
        },
        setProcess(state, payload) {
            payload.status !== undefined ? state.processIsUnderway.status = payload.status : false;
            payload.title !== undefined ? state.processIsUnderway.title = payload.title : false;
        },
        setErrorData(state, payload) {
            state.error.statusCode = payload.statusCode;
            state.error.message = payload.message;
            state.error.showError = payload.showError;
        },
        setConfirmData(state, payload) {
            state.confirm.headerText = payload.headerText;
            state.confirm.iconPath = payload.iconPath;
            state.confirm.text = payload.text;
            state.confirm.showModal = payload.showModal;
            state.confirm.buttons = payload.buttons;
            state.confirm.onClose = payload.onClose;
        },
        setProjectOptions(state, payload) {
            state.options.project = { name: payload.name, id: payload.id };
        },
        setObjectOptions(state, payload) {
            state.options.object = { name: payload.name, id: payload.id };
        },
        setLayerOptions(state, payload) {
            state.options.layer = { name: payload.name, id: payload.id };
        },
        setObjectCaseOptions(state, payload) {
            state.options.case = { name: payload.name, id: payload.id };
        },
        setLayerCaseOptions(state, payload) {
            state.options.layer_case = { name: payload.name, id: payload.id, type: payload.type };
        },
        setEngineeringCaseOptions(state, payload) {
            state.options.engineering_case = { name: payload.name, id: payload.id };
        },
        setEconomicsCaseOptions(state, payload) {
            state.options.economics_case = { name: payload.name, id: payload.id };
        },
        setBreadcrumbs(state, payload) {
            state.breadcrumbs = payload;
        },
        pushNotifications(state, payload) {
            const notificationsList = payload

            notificationsList.forEach(item => {
                state.notifications.items.push(item)
            })
        },
        setActiveNotifications(state, payload) {
            state.notifications.isActive = payload
        },
        setRequestPendingStatus(state, payload) {
            state.requestIsPending = payload
        },
        setSockedConnectionStatus(state, payload) {
            state.sockedConnectionStatus = payload
        }
    },
    actions: {
        async copyImageToClipboard({ state }, payload) {
            const scale = 2
            const DOMElement = payload

            const isDOMElement = (elem) => {
                try {
                    return elem instanceof HTMLElement
                }
                catch (e) {
                    return (typeof elem === 'object') && (elem.nodeType === 1) && (typeof elem.style === 'object') && (typeof elem.ownerDocument === 'object')
                }
            }

            if (!isDOMElement(DOMElement)) return

            try {
                this.commit('common/setProcess', {
                    status: 'loading',
                    title: 'copy_image.generating'
                })
            
                const dataUrl = await domtoimage.toPng(DOMElement, {
                    width: DOMElement.clientWidth * scale,
                    height: DOMElement.clientHeight * scale,
                    style: {
                        transform: `scale(${scale})`,
                        transformOrigin: 'top left'
                    }
                })
                
                await copyImageToClipboard(dataUrl)
    
                this.commit('common/setProcess', {
                    status: 'display',
                    title: 'copy_image.success'
                })
            } catch (e) {
                this.commit('common/setProcess', {
                    status: 'error',
                    title: 'copy_image.error'
                })
                useNuxtApp().$commonError('failed to copy image to clipboard', e)
            }
        }
    }
}