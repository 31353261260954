import { default as _91company_id_93U1u3SJEXRsMeta } from "/var/www/dev/app/pages/company/[company_id].vue?macro=true";
import { default as indexPmjeny0DQkMeta } from "/var/www/dev/app/pages/index.vue?macro=true";
import { default as loginoptOg53IbbMeta } from "/var/www/dev/app/pages/login.vue?macro=true";
import { default as _91case_id_93XEk5sCodBUMeta } from "/var/www/dev/app/pages/projects/[project_id]/economics/[case_id].vue?macro=true";
import { default as compare5NqhhFkg8rMeta } from "/var/www/dev/app/pages/projects/[project_id]/economics/compare.vue?macro=true";
import { default as indexuN7ceSDqeNMeta } from "/var/www/dev/app/pages/projects/[project_id]/economics/index.vue?macro=true";
import { default as _91case_id_93xGKupTrPzwMeta } from "/var/www/dev/app/pages/projects/[project_id]/engineering/[case_id].vue?macro=true";
import { default as indexa28SmJ4d62Meta } from "/var/www/dev/app/pages/projects/[project_id]/engineering/index.vue?macro=true";
import { default as indexPIp42F8AOYMeta } from "/var/www/dev/app/pages/projects/[project_id]/index.vue?macro=true";
import { default as _91case_id_93x1cfuPHJTiMeta } from "/var/www/dev/app/pages/projects/[project_id]/layers/[layer_id]/cases/[case_id].vue?macro=true";
import { default as indexRnMjAUtwobMeta } from "/var/www/dev/app/pages/projects/[project_id]/layers/[layer_id]/index.vue?macro=true";
import { default as indexJySkVdL79RMeta } from "/var/www/dev/app/pages/projects/[project_id]/layers/index.vue?macro=true";
import { default as _91case_id_93EsKJSinxJ9Meta } from "/var/www/dev/app/pages/projects/[project_id]/objects/[object_id]/cases/[case_id].vue?macro=true";
import { default as indexJUrid9mIEhMeta } from "/var/www/dev/app/pages/projects/[project_id]/objects/[object_id]/index.vue?macro=true";
import { default as index4eztGl8FfbMeta } from "/var/www/dev/app/pages/projects/[project_id]/objects/index.vue?macro=true";
import { default as _91tax_id_93Bd4ml9gZpTMeta } from "/var/www/dev/app/pages/projects/[project_id]/taxes/[tax_id].vue?macro=true";
import { default as indexAFDXlKcu7bMeta } from "/var/www/dev/app/pages/projects/index.vue?macro=true";
import { default as indexcqSViejF3vMeta } from "/var/www/dev/app/pages/system/index.vue?macro=true";
import { default as jobsD6sxlAzC6HMeta } from "/var/www/dev/app/pages/system/jobs.vue?macro=true";
import { default as _91user_id_93q13Aum1jFHMeta } from "/var/www/dev/app/pages/user/[user_id].vue?macro=true";
export default [
  {
    name: "company-company_id",
    path: "/company/:company_id()",
    meta: _91company_id_93U1u3SJEXRsMeta || {},
    component: () => import("/var/www/dev/app/pages/company/[company_id].vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/dev/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginoptOg53IbbMeta || {},
    component: () => import("/var/www/dev/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "projects-project_id-economics-case_id",
    path: "/projects/:project_id()/economics/:case_id()",
    meta: _91case_id_93XEk5sCodBUMeta || {},
    component: () => import("/var/www/dev/app/pages/projects/[project_id]/economics/[case_id].vue").then(m => m.default || m)
  },
  {
    name: "projects-project_id-economics-compare",
    path: "/projects/:project_id()/economics/compare",
    meta: compare5NqhhFkg8rMeta || {},
    component: () => import("/var/www/dev/app/pages/projects/[project_id]/economics/compare.vue").then(m => m.default || m)
  },
  {
    name: "projects-project_id-economics",
    path: "/projects/:project_id()/economics",
    meta: indexuN7ceSDqeNMeta || {},
    component: () => import("/var/www/dev/app/pages/projects/[project_id]/economics/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-project_id-engineering-case_id",
    path: "/projects/:project_id()/engineering/:case_id()",
    meta: _91case_id_93xGKupTrPzwMeta || {},
    component: () => import("/var/www/dev/app/pages/projects/[project_id]/engineering/[case_id].vue").then(m => m.default || m)
  },
  {
    name: "projects-project_id-engineering",
    path: "/projects/:project_id()/engineering",
    meta: indexa28SmJ4d62Meta || {},
    component: () => import("/var/www/dev/app/pages/projects/[project_id]/engineering/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-project_id",
    path: "/projects/:project_id()",
    meta: indexPIp42F8AOYMeta || {},
    component: () => import("/var/www/dev/app/pages/projects/[project_id]/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-project_id-layers-layer_id-cases-case_id",
    path: "/projects/:project_id()/layers/:layer_id()/cases/:case_id()",
    meta: _91case_id_93x1cfuPHJTiMeta || {},
    component: () => import("/var/www/dev/app/pages/projects/[project_id]/layers/[layer_id]/cases/[case_id].vue").then(m => m.default || m)
  },
  {
    name: "projects-project_id-layers-layer_id",
    path: "/projects/:project_id()/layers/:layer_id()",
    meta: indexRnMjAUtwobMeta || {},
    component: () => import("/var/www/dev/app/pages/projects/[project_id]/layers/[layer_id]/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-project_id-layers",
    path: "/projects/:project_id()/layers",
    meta: indexJySkVdL79RMeta || {},
    component: () => import("/var/www/dev/app/pages/projects/[project_id]/layers/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-project_id-objects-object_id-cases-case_id",
    path: "/projects/:project_id()/objects/:object_id()/cases/:case_id()",
    meta: _91case_id_93EsKJSinxJ9Meta || {},
    component: () => import("/var/www/dev/app/pages/projects/[project_id]/objects/[object_id]/cases/[case_id].vue").then(m => m.default || m)
  },
  {
    name: "projects-project_id-objects-object_id",
    path: "/projects/:project_id()/objects/:object_id()",
    meta: indexJUrid9mIEhMeta || {},
    component: () => import("/var/www/dev/app/pages/projects/[project_id]/objects/[object_id]/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-project_id-objects",
    path: "/projects/:project_id()/objects",
    meta: index4eztGl8FfbMeta || {},
    component: () => import("/var/www/dev/app/pages/projects/[project_id]/objects/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-project_id-taxes-tax_id",
    path: "/projects/:project_id()/taxes/:tax_id()",
    meta: _91tax_id_93Bd4ml9gZpTMeta || {},
    component: () => import("/var/www/dev/app/pages/projects/[project_id]/taxes/[tax_id].vue").then(m => m.default || m)
  },
  {
    name: "projects",
    path: "/projects",
    meta: indexAFDXlKcu7bMeta || {},
    component: () => import("/var/www/dev/app/pages/projects/index.vue").then(m => m.default || m)
  },
  {
    name: "system",
    path: "/system",
    meta: indexcqSViejF3vMeta || {},
    component: () => import("/var/www/dev/app/pages/system/index.vue").then(m => m.default || m)
  },
  {
    name: "system-jobs",
    path: "/system/jobs",
    meta: jobsD6sxlAzC6HMeta || {},
    component: () => import("/var/www/dev/app/pages/system/jobs.vue").then(m => m.default || m)
  },
  {
    name: "user-user_id",
    path: "/user/:user_id()",
    meta: _91user_id_93q13Aum1jFHMeta || {},
    component: () => import("/var/www/dev/app/pages/user/[user_id].vue").then(m => m.default || m)
  }
]