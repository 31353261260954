import * as Sentry from "@sentry/browser";

export default defineNuxtPlugin(nuxtApp => {
    const userData = useAuth()

    nuxtApp.vueApp.config.errorHandler = (error, instance, info) => {
        nuxtApp.vueApp._context.provides.store.commit("common/setNotification", {
			isActive: true,
			text: `Error: ${error.message} in ${JSON.stringify(info)}`
		})

        console.log(error)

        Sentry.getCurrentScope().setExtras({
            instance: { ...instance },
            info: info,
            user: { ...userData.user.user }
        })

        Sentry.captureException(error)
    }

    nuxtApp.provide('economicCaseError', (text, error) => {
        throw new Error(`Economics: ${text}`, { cause: error })
    })

    nuxtApp.provide('socketError', (text, error) => {
        throw new Error(`WebSocket error: ${text}`, { cause: error })
    })

    nuxtApp.provide('commonError', (text, error) => {
        throw new Error(`Common error: ${text}`, { cause: error })
    })
})